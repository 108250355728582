import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'se-test',
  template: ` <p>test Works!</p> `,
  styles: [],
  standalone: true,
})
export class TestComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
