// import 'zone.js'; // Included with Angular CLI.

import { NgZone, enableProdMode, ɵNoopNgZone } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideRouter } from '@angular/router';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routing';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [provideRouter(routes), { provide: NgZone, useClass: ɵNoopNgZone }],
});
