import { Routes } from '@angular/router';
import { TestComponent } from './test/test.component';

export const routes: Routes = [
  { path: 'test', component: TestComponent },
  {
    path: '',
    loadChildren: () => import('./page/page.routing').then((m) => m.routes),
  },
];
